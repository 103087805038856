import { css } from '@emotion/react'
import Link from 'next/link'
import { colors, mq } from 'plume-ui'
import React from 'react'
import { useTimeout } from '../../../hooks/useTimeout'
import { IcomoonIcon } from '../../atoms/IcomoonIcon'

type TopNotificationProps = {
  category: string
  text: string
  detailUrl: string
}

export const TopDesktopNotification: React.VFC<TopNotificationProps> = (
  props
) => {
  const isTimeout = useTimeout(2000)

  return (
    <div
      className="new-top-notification"
      css={css`
        display: flex;
        height: 40px;
        width: 100%;
        transition: all 1s cubic-bezier(0.67, 0.01, 1, 1);
        background-color: ${isTimeout ? colors.white00 : '#0071e3'};
        color: ${isTimeout ? colors.black30 : colors.white00};
        justify-content: center;
        ${mq.untilMedium} {
          display: none;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            display: flex;
            height: 20px;
            width: 100px;
            transition: all 1.5s ease-in;
            background-color: ${isTimeout ? colors.black10 : '#0051d6'};
            align-items: center;
            justify-content: center;
            font-size: 12px;
            line-height: 1;
            color: ${colors.white00};
            border-radius: 2px;
          `}
        >
          {props.category}
        </div>
        <div
          css={css`
            margin: 0 24px;
            font-size: 14px;
            line-height: 21px;
          `}
        >
          {props.text}
        </div>
        <div
          css={css`
            font-size: 14px;
            line-height: 21px;
          `}
        >
          <Link href={props.detailUrl} passHref>
            <a
              className="notification-path"
              target="_blank"
              rel="noopener noreferrer"
              css={css`
                color: ${!isTimeout ? colors.white00 : colors.link};
                transition: color 1.5s ease-in;
              `}
            >
              <span
                css={css`
                  transition: text-decoration 0.2s ease 0s;
                  text-decoration: underline solid transparent;
                  &:hover {
                    text-decoration: underline solid Currentcolor;
                    transition: text-decoration 0.2s ease 0s;
                  }
                `}
              >
                詳しく見る
              </span>
              <IcomoonIcon
                css={css`
                  font-size: 9px;
                  font-weight: bold;
                  margin-left: 5px;
                  position: relative;
                  top: -1px;
                `}
                name="arrow4-right"
              />
            </a>
          </Link>
        </div>
      </div>
    </div>
  )
}

type TopMobileNotificationProps = {
  text: string
  detailUrl: string
}

export const TopMobileNotification: React.VFC<TopMobileNotificationProps> = (
  props
) => {
  const isTimeout = useTimeout(2000)

  return (
    <Link href={props.detailUrl} passHref>
      <a
        className="notification-path"
        target="_blank"
        rel="noopener noreferrer"
        css={css`
          display: none;
          ${mq.untilMedium} {
            display: flex;
          }
          padding: 8px 16px;
          width: 100%;
          min-height: 48px;
          justify-content: space-between;
          transition: all 1s cubic-bezier(0.67, 0.01, 1, 1);
          background-color: ${isTimeout ? colors.white00 : '#0071e3'};
          color: ${isTimeout ? colors.black30 : colors.white00};
          font-size: 14px;
          line-height: 150%;
          align-items: center;
        `}
      >
        <span className="new-top-mobile-notification">{props.text}</span>
        <IcomoonIcon
          css={css`
            font-size: 9px;
            font-weight: bold;
            margin-left: 5px;
            position: relative;
            top: -1px;
          `}
          name="arrow4-right"
        />
      </a>
    </Link>
  )
}
