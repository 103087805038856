import React from 'react'
import { formatDate } from '@/lib/date'
import { IGNORED_BY_WOVN_CLASS_NAME } from '@/lib/wovn'

type Props = {
  children?: string | null
  className?: string
}

// Returns formatted date of iso string
export const FormattedDate: React.FC<Props> = (props) => {
  if (!props.children) {
    return null
  }
  return (
    <span className={props.className}>
      <span className={IGNORED_BY_WOVN_CLASS_NAME} suppressHydrationWarning>
        {formatDate(props.children, 'yyyy/MM/dd')}
      </span>
    </span>
  )
}
