import React from 'react'
import { css } from '@emotion/react'
import Link from 'next/link'
import { colors } from 'plume-ui'
import { IcomoonIcon } from '../../atoms/IcomoonIcon'

type Props = {
  href: string
  className?: string
}

export const ShowAllLink: React.VFC<Props> = (props) => {
  return (
    <Link href={props.href} prefetch={false} passHref>
      <a
        css={css`
          font-size: 14px;
          color: ${colors.text};
          white-space: nowrap;
          &:hover {
            color: ${colors.link};
          }
        `}
        className={`view-all ${props.className}`}
        target="_blank"
      >
        すべて見る
        <IcomoonIcon
          css={css`
            font-size: 9px;
            font-weight: bold;
            margin-left: 5px;
            position: relative;
            top: -1px;
          `}
          name="arrow4-right"
        />
      </a>
    </Link>
  )
}
