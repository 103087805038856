import React from 'react'
import { css } from '@emotion/react'
import Link from 'next/link'
import { colors } from 'plume-ui'
import { newTagPath, TAG_HREF } from '@/lib/url'
import { ContentfulNewTag } from '../../types'
import { TagData } from '../../types/api'
import { Tag } from '../pages/companies/actions'
import { TagInBenchmark } from '@/lib/api/company/domesticBenchmark'

type Props = {
  tag: TagData | ContentfulNewTag | Tag | TagInBenchmark
  className?: string
}

export const TagButton: React.FC<Props> = (props) => {
  return (
    <li
      css={css`
        display: inline-block;
        margin: 0 4px 4px 0;
      `}
      data-test-id="tag"
    >
      <Link
        href={TAG_HREF}
        as={'slug' in props.tag ? newTagPath(props.tag) : props.tag.path}
        passHref
      >
        <a
          data-test-id="tag-link"
          css={css`
            background-color: ${colors.gray40};
            border-radius: 3px;
            padding: 2px 6px;
            font-size: 12px;
            line-height: 1.5;
            color: ${colors.gray90};
            text-align: center;
            display: block;
            white-space: nowrap;
            transition: 0.3s;

            &:hover {
              color: ${colors.white00};
              background-color: ${colors.gray70};
            }
          `}
          className={`tag-button ${props.className ? props.className : ''}`}
        >
          {`#${props.tag.name}`}
        </a>
      </Link>
    </li>
  )
}

type TagButtonGroupProps = {
  className?: string
  children: React.ReactNode
  dataTestId?: string
}

export const TagButtonGroup: React.FC<TagButtonGroupProps> = (props) => {
  return (
    <ul
      css={css`
        margin: 0 0 -4px;
        padding: 0;
      `}
      className={props.className}
      data-test-id={props.dataTestId}
    >
      {props.children}
    </ul>
  )
}
