import { throttle } from 'lodash'
import { useEffect, useState } from 'react'

export const useIsTop = (initialState: boolean) => {
  const [isTop, setIsTop] = useState(initialState)

  const checkIfTop = () => {
    // 16px は違和感がない範囲を見て決めた
    if (window.scrollY >= 16) {
      setIsTop(false)
    } else {
      setIsTop(true)
    }
  }

  useEffect(checkIfTop, [])
  useEffect(() => {
    document.addEventListener('scroll', throttle(checkIfTop, 200))
    return (): void =>
      document.removeEventListener('scroll', throttle(checkIfTop, 200))
  }, [])

  return isTop
}
