import Head from 'next/head'
import * as React from 'react'
import {
  articlesUrl,
  articleUrl,
  companyUrl,
  customerInterviewUrl,
  enterpriseCircleUrl,
  featuredUrl,
  host,
  investorUrl,
  newTagUrl,
  noticePath,
  resourcesUrl,
  resourceUrl,
  seminarsUrl,
  seminarUrl,
  twitterUserName,
} from '@/lib/url'
import {
  Article,
  CustomerInterview,
  Featured,
  Investor,
  Notice,
  Report,
  Seminar,
} from '../../types'
import { CompanyData, OverseasCompanyData, TagData } from '../../types/api'

// ref: https://developer.twitter.com/en/docs/tweets/optimize-with-cards/guides/getting-started
type TwitterCardType = 'summary' | 'summary_large_image' | 'app' | 'player'

type Props = {
  title?: string
  description?: string
  image?: string
  url?: string
  twitterCard?: TwitterCardType
  children?: React.ReactNode
}

export const DefaultHead: React.FC<Props> = ({
  title = 'スピーダ スタートアップ情報リサーチ',
  description = 'スタートアップ投資や協業を成功させるために、事業内容・資金調達状況・提携先・関連ニュース・投資家情報・分析レポートなどの検索から取得管理までシームレスに行える情報プラットフォームです。',
  image = `${host}/static/images/ogp.png`,
  url = 'https://initial.inc/',
  twitterCard = 'summary_large_image',
  children,
}) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content={
          'スピーダ,スタートアップ,NewsPicksスタートアップ,startup,ベンチャー'
        }
      />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta
        property="og:site_name"
        content={'スピーダ スタートアップ情報リサーチ'}
      />
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:site" content={`@${twitterUserName}`} />
      <meta name="twitter:creator" content={`@${twitterUserName}`} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <link rel="canonical" href={url} />
      <meta
        name="viewport"
        content="width=device-width,height=device-height"
        key="viewport"
      />
      {children}
    </Head>
  )
}

export const ArticlesHead: React.FC = () => {
  return (
    <DefaultHead
      title={'オリジナル記事｜スピーダ スタートアップ情報リサーチ'}
      description={
        'スピーダ スタートアップ情報リサーチのオリジナル記事はこちら'
      }
      url={articlesUrl}
    />
  )
}

export const ArticleHead: React.FC<{ article: Article }> = ({ article }) => {
  return (
    <DefaultHead
      title={`${article.title}｜スピーダ スタートアップ情報リサーチ`}
      description={article.introduction}
      url={articleUrl(article)}
      image={article.headerImage.url}
    >
      <script
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Article',
            headline: article.title,
            image: [article.headerImage.url],
            datePublished: article.publishedAt,
          }),
        }}
        type="application/ld+json"
      ></script>
    </DefaultHead>
  )
}

export const SeminarHead: React.FC<{ seminar: Seminar }> = ({ seminar }) => {
  return (
    <DefaultHead
      title={`${seminar.title}｜スピーダ スタートアップ情報リサーチ`}
      description={seminar.introduction}
      url={seminarUrl(seminar.slug)}
      image={seminar.headerImage.url}
    ></DefaultHead>
  )
}

export const SeminarsHead: React.FC<{}> = () => {
  return (
    <DefaultHead
      title={`セミナー・イベント｜スピーダ スタートアップ情報リサーチ`}
      description={
        'スピーダ スタートアップ情報リサーチのセミナー・イベント一覧はこちら'
      }
      url={seminarsUrl}
    ></DefaultHead>
  )
}

export const ResourcesHead: React.FC<{}> = () => {
  return (
    <DefaultHead
      title={`資料・レポート｜スピーダ スタートアップ情報リサーチ`}
      description={
        'スピーダ スタートアップ情報リサーチの資料・レポート一覧はこちら'
      }
      url={resourcesUrl}
    ></DefaultHead>
  )
}

export const ResourceHead: React.FC<{ report: Report }> = ({ report }) => {
  return (
    <DefaultHead
      title={`${report.title}｜スピーダ スタートアップ情報リサーチ`}
      description={report.introduction}
      url={resourceUrl(report.slug)}
      image={report.headerImage.url}
    ></DefaultHead>
  )
}

export const NewTagHead: React.FC<{ tag: TagData }> = ({ tag }) => {
  return (
    <DefaultHead
      title={`${tag.name}｜スピーダ スタートアップ情報リサーチ`}
      url={newTagUrl(tag)}
    >
      <meta name="viewport" content="width=1200px" key="viewport" />
    </DefaultHead>
  )
}

export const CompanyHead: React.FC<{
  company: CompanyData
  children: JSX.Element | null
}> = ({ company, children }) => {
  return (
    <DefaultHead
      title={`${company.name}｜スピーダ スタートアップ情報リサーチ`}
      description={company.description}
      url={companyUrl(company)}
      image={company.imageUrl}
      twitterCard={company.imageUrl ? 'summary' : undefined}
    >
      {children}
    </DefaultHead>
  )
}

export const OverseasCompanyHead: React.FC<{
  company: OverseasCompanyData
  children: JSX.Element | null
}> = ({ company, children }) => {
  return (
    <DefaultHead
      title={`${company.name}｜スピーダ スタートアップ情報リサーチ`}
      description={company.description || ''}
    >
      {children}
    </DefaultHead>
  )
}

export const InvestorHead: React.FC<{ investor: Investor }> = ({
  investor,
}) => {
  return (
    <DefaultHead
      title={`${investor.name}｜スピーダ スタートアップ情報リサーチ`}
      description={investor.description}
      url={investorUrl(investor.shortname)}
      image={investor.imageUrl}
      twitterCard={investor.imageUrl ? 'summary' : undefined}
    />
  )
}

export const CustomerInterviewHead: React.FC<{
  interview: CustomerInterview
}> = ({ interview }) => {
  return (
    <DefaultHead
      title={`${interview.note.name}｜スピーダ スタートアップ情報リサーチ`}
      description={interview.introduction}
      url={customerInterviewUrl(interview)}
      image={interview.headerImage.url}
    />
  )
}

export const RequestDownloadHead: React.FC = () => {
  return (
    <DefaultHead
      title="資料ダウンロード｜スピーダ スタートアップ情報リサーチ"
      image={`${host}/static/images/enterprise/index/ogp_enterprise.png`}
      url={'https://initial.inc/enterprise/request'}
    />
  )
}

export const RequestTrialHead: React.FC = () => {
  return (
    <DefaultHead
      title="無料トライアル｜スピーダ スタートアップ情報リサーチ"
      image={`${host}/static/images/enterprise/index/ogp_enterprise.png`}
      url={'https://initial.inc/enterprise/contact'}
    />
  )
}

export const FeaturedHead: React.FC<{ featured: Featured }> = ({
  featured,
}) => {
  return (
    <DefaultHead
      title={`${featured.title}｜スピーダ スタートアップ情報リサーチ`}
      description={featured.description}
      url={featuredUrl(featured.slug)}
      image={featured.ogpImage.url}
    ></DefaultHead>
  )
}

export const SearchHead: React.FC = () => {
  return (
    <DefaultHead title="検索｜スピーダ スタートアップ情報リサーチ">
      <meta name="viewport" content="width=1200px" key="viewport" />
    </DefaultHead>
  )
}

export const RoundsHead: React.FC = () => {
  return (
    <DefaultHead
      title={'資金調達検索｜スピーダ スタートアップ情報リサーチ'}
      description={'スピーダ スタートアップ情報リサーチの資金調達検索はこちら'}
    ></DefaultHead>
  )
}

export const StatusSettingHead: React.FC = () => {
  return (
    <DefaultHead
      title={'ステータス設定｜スピーダ スタートアップ情報リサーチ'}
      description={
        'スピーダ スタートアップ情報リサーチのステータス設定についてはこちら'
      }
    ></DefaultHead>
  )
}

export const NoticeHead: React.FC<{ notice: Notice }> = ({ notice }) => {
  return (
    <DefaultHead
      title={`${notice.title}｜スピーダ スタートアップ情報リサーチ`}
      image={notice.headerImage.url}
      description={notice.body?.slice(0, 100)}
      url={notice.slug ? host + noticePath(notice.slug) : host}
    />
  )
}

export const SearchCompaniesHead: React.FC = () => {
  return (
    <DefaultHead
      title={'スタートアップ｜スピーダ スタートアップ情報リサーチ'}
      description={
        'スピーダ スタートアップ情報リサーチのスタートアップ企業検索はこちら'
      }
    >
      <meta name="viewport" content="width=1200px" key="viewport" />
    </DefaultHead>
  )
}

export const SearchInvestorsHead: React.FC = () => (
  <DefaultHead
    title={'投資家｜スピーダ スタートアップ情報リサーチ'}
    description={'スピーダ スタートアップ情報リサーチの投資家検索はこちら'}
  />
)

export const CircleHead: React.FC = () => {
  return (
    <DefaultHead
      title="ユーザーコミュニティ「INITIAL Circle」｜INITIAL"
      description="INITIALユーザーの知見と人脈をつなぎ、循環させるためのユーザーコミュニティ「INITIAL Circle」。INITIALを利用する、VC、CVC、事業会社、行政、金融、研究機関、スタートアップのみなさんが、所属する組織や地域、立場の垣根を超えて、自由に交流する場です。"
      url={enterpriseCircleUrl}
      image={`${host}/static/images/enterprise/index/ogp_enterprise.png`}
    />
  )
}
