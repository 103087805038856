import React from 'react'
import { css } from '@emotion/react'
import { colors } from 'plume-ui'
import { formatDateByDistanceToNow } from '@/lib/date'

export const NPFeedPublisherAndPublishedDate: React.FC<{
  publisher: string
  publishedDate: string
  lineHeight?: number
  fontSize?: string
  className?: string
}> = ({
  publisher,
  publishedDate,
  lineHeight = 1.0,
  fontSize = '14px',
  className,
}) => {
  return (
    <div
      className={className}
      css={css`
        font-size: ${fontSize};
        line-height: ${lineHeight};
        color: ${colors.textLight};
        display: flex;
        flex-flow: row nowrap;
      `}
    >
      <span
        className="np-feed-publisher"
        css={css`
          display: inline-block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: min-content;
          height: 100%;
          max-width: 580px;
          overflow: hidden;
        `}
      >
        {publisher}
      </span>
      <span
        css={css`
          margin: 0px 4px;
        `}
      >
        ・
      </span>
      <span
        className="np-feed-publish-date"
        css={css`
          word-break: keep-all;
        `}
      >
        {formatDateByDistanceToNow(publishedDate)}
      </span>
    </div>
  )
}
