import { useEffect, useState } from 'react'

export const useTimeout = (timeout: number) => {
  const [isTimeout, setIsTimeout] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTimeout(true)
    }, timeout)
    return () => clearTimeout(timer)
  }, [])

  return isTimeout
}
