import { NPFeed } from '../types'

const REPORT_BANNER_POSITION = 8

export const isBannerPosition = (feedsLength: number, index: number) => {
  const feedsLengthAboveReportBanner = REPORT_BANNER_POSITION - 1
  return (
    (feedsLength < feedsLengthAboveReportBanner && feedsLength === index + 1) ||
    index + 1 === feedsLengthAboveReportBanner
  )
}

export const toOddNumberFeeds = (feeds: NPFeed[]): NPFeed[] => {
  if (feeds.length % 2 === 0) {
    return feeds.slice(0, feeds.length - 1)
  } else {
    return feeds
  }
}
