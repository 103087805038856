import React from 'react'
import { css } from '@emotion/react'
import { Picker } from '../../../types'

type PickerIconsProps = {
  className?: string
  pickers: Array<Pick<Picker, 'image'>>
}

export const PickersIcons: React.VFC<PickerIconsProps> = (props) => {
  return (
    <div
      className={props.className}
      css={css`
        height: ${24 + 2 * 2}px;
        margin-right: -${6 * (props.pickers.length - 1)}px;
      `}
    >
      {props.pickers.map((p, i) => (
        <img
          alt="picker icon"
          className="np-feed-picker-icon"
          css={css`
            position: relative;
            display: inline-block;
            box-sizing: content-box;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 2px solid white;
            top: 0%;
            left: ${-6 * i + 'px'};
            z-index: ${18 - i};
          `}
          src={
            p.image ? p.image : '/static/images/top/np-picker-blank-avatar.png'
          }
          key={i}
        ></img>
      ))}
    </div>
  )
}
