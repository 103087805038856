import { css } from '@emotion/react'
import Link from 'next/link'
import { colors, CompanyLogo, mq } from 'plume-ui'
import { CompanyData } from '../../../types/api'
import { LineClamp } from '../../atoms/LineClamp'
import { LineClampText } from '../../atoms/LineClampText'
import { TagButton } from '../../atoms/TagButton'
import { TopSectionCard, TopSectionCardTitle } from './SubSection'

type Props = {
  companies: CompanyData[]
}

export const CompanyAccessRanking: React.VFC<Props> = (props) => {
  return (
    <TopSectionCard id="access-ranking-section" className="access-ranking">
      <TopSectionCardTitle
        title="アクセスランキング"
        subTitle="国内スタートアップ"
      />
      <div
        css={css`
          > *:not(:last-child) {
            position: relative;
          }
          > *:not(:last-child)::after {
            content: '';
            position: absolute;
            height: 1px;
            bottom: 0px;
            left: 16px;
            width: calc(100% - 32px);
            background-color: ${colors.gray50};
          }
          ${mq.untilMedium} {
            overflow: hidden;
            border-radius: 8px;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
          }
        `}
      >
        {props.companies.slice(0, 5).map((company, index) => (
          <CompanyAccessRankingItem
            key={index}
            company={company}
            rank={index + 1}
          />
        ))}
      </div>
    </TopSectionCard>
  )
}

type CompanyItemProps = {
  company: CompanyData
  rank: number
}

const rankingImg = (rank: number) =>
  `/static/images/ranking/icon_ranking-${rank}@2x.png`

const CompanyAccessRankingItem: React.VFC<CompanyItemProps> = (props) => {
  return (
    <div
      css={css`
        background-color: ${colors.white00};
      `}
    >
      <Link href={props.company.path} passHref>
        <a
          className="company-item"
          css={css`
            display: inline-block;
            color: ${colors.black10};
            padding: 16px 24px 24px;
            width: 100%;
            &:hover {
              background-color: rgba(0, 0, 0, 0.03);
            }
            ${mq.untilMedium} {
              padding: 16px;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 8px;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                flex: 1;
              `}
            >
              <img
                alt="company rank"
                src={rankingImg(props.rank)}
                className="company-rank"
                css={css`
                  width: 20px;
                  height: 20px;
                  margin-right: 8px;
                `}
              />
              <div
                className="company-name"
                css={css`
                  width: 100%;
                  margin-right: 16px;
                `}
              >
                <span
                  css={css`
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 140%;
                    text-decoration: underline solid transparent;
                    transition: text-decoration 0.2s ease 0s;
                    &:hover {
                      text-decoration: underline solid Currentcolor;
                      transition: text-decoration 0.2s ease 0s;
                    }
                    ${mq.untilMedium} {
                      font-size: 15px;
                    }
                  `}
                >
                  {props.company.name}
                </span>
              </div>
            </div>
            <CompanyLogo
              src={
                props.company.imageUrl || '/static/images/default/company.png'
              }
              name={props.company.name}
              className="company-logo"
              css={css`
                &:hover {
                  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
                }
              `}
            />
          </div>
          <div
            css={css`
              color: ${colors.black30};
              overflow: hidden;
            `}
          >
            <LineClampText
              className="company-description"
              lineNumber={4}
              height={1.5}
              size={'14px'}
            >
              {props.company.description}
            </LineClampText>
            <LineClamp
              className="company-tags"
              lineNumber={2}
              css={css`
                color: ${colors.textLight};
                line-height: 1.75;
                margin-top: 8px;
              `}
            >
              {props.company.tags.map((tag, i) => (
                // Safariのline-clampの挙動がおかしい対応でspanで囲っている
                <span
                  key={i}
                  css={css`
                    display: inline-block;
                    &:not(:last-of-type) {
                      margin-right: 4px;
                    }
                  `}
                >
                  <TagButton tag={tag} />
                </span>
              ))}
            </LineClamp>
          </div>
        </a>
      </Link>
    </div>
  )
}
