import * as React from 'react'
import { css } from '@emotion/react'
import { mq } from 'plume-ui'

type LineNumber =
  | number
  | {
      desktop: number
      mobile: number
    }

type Props = {
  lineNumber: LineNumber
  showAll?: boolean
  height?: number
  size?: string
  stripLineBreaks?: boolean
  justify?: boolean
  lineClampRef?: any
  className?: string
  title?: string
  children: React.ReactNode
}

export const LineClamp: React.FC<Props> = ({
  lineNumber,
  showAll = false,
  justify = false,
  lineClampRef,
  className,
  children,
  title,
}) => {
  if (typeof lineNumber === 'number') {
    lineNumber = { desktop: lineNumber, mobile: lineNumber }
  }
  return (
    <div
      css={css`
        ${justify ? 'text-align: justify;' : ''}

        ${showAll ||
        `
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: ${lineNumber.desktop};
          ${mq.untilMedium} {
            -webkit-line-clamp: ${lineNumber.mobile};
            line-height: 1.75;
          }
        `}
      `}
      className={className}
      ref={lineClampRef}
      title={title}
    >
      {children}
    </div>
  )
}
