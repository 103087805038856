import { css } from '@emotion/react'
import { throttle } from 'lodash'
import { mq, colors } from 'plume-ui'
import React, { useRef, useEffect } from 'react'
import { useIsTop } from '../../../hooks/useIsTop'
import { isEnterpriseWithTrial } from '@/lib/user'
import { useUser } from '../../../contexts/AuthContext'

export const MobileTopNavBar: React.VFC = () => {
  const isTop = useIsTop(true)
  const ref = useRef<HTMLDivElement>(null)
  const user = useUser()

  const check = () => {
    if (ref.current === null) return
    const activeButton = ref.current.querySelector<HTMLElement>(
      '.top-nav-bar-button.active'
    )
    if (activeButton === null) return
    ref.current.scroll({
      left: activeButton.offsetLeft - 32,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', throttle(check, 200))
    return () => {
      window.removeEventListener('scroll', throttle(check, 200))
    }
  }, [])

  return (
    <div
      className="top-nav-bar"
      css={css`
        display: none;
        ${mq.untilMedium} {
          position: sticky;
          display: block;
          top: 54px;
          z-index: 19;
          overflow-x: auto;
          transition: background-color 0.2s ease;
          background-color: ${isTop ? 'transparent' : colors.white00};
          box-shadow: ${isTop ? 'none' : '0px 0px 4px rgba(0, 0, 0, 0.08)'};
          height: 48px;
          width: 100vw;
          padding: 10px 0;
          white-space: nowrap;
          scrollbar-width: none;
          -ms-overflow-style: none;
          font-size: 14px;
          line-height: 1;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      `}
      ref={ref}
    >
      <div
        css={css`
          align-items: center;
          display: flex;
          height: 100%;
          width: min-content;
          white-space: nowrap;
          padding: 0 16px;
          > *:not(:last-child) {
            margin-right: 4px;
          }
        `}
      >
        <MobileTopNavBarButton name="記事" id="np-feed" />
        <MobileTopNavBarButton
          name="資金調達ニュース"
          id="finance-ma-section"
        />
        <MobileTopNavBarButton
          name="スタートアップアクセスランキング"
          id="access-ranking-section"
        />
        {isEnterpriseWithTrial(user) && (
          <MobileTopNavBarButton
            name="国内新規登録スタートアップ"
            id="latest-company-section"
          />
        )}
      </div>
    </div>
  )
}

const MobileTopNavBarButton: React.VFC<{ name: string; id: string }> = (
  props
) => {
  const [isActive, setIsActive] = React.useState<boolean>(false)
  const navigationSectionHeight = 55 + 53

  const onClick = () => {
    const targetElement = document.getElementById(props.id)
    if (targetElement === null) return
    const positionTop = targetElement.offsetTop
    window.scrollTo({
      top: positionTop - navigationSectionHeight,
      behavior: 'smooth',
    })
  }

  const checkIfActive = () => {
    const targetElement = document.getElementById(props.id)
    if (targetElement === null) return
    const topPosition = props.id === 'np-feed' ? 0 : targetElement.offsetTop
    const elementHeight = targetElement.clientHeight
    const bottomPosition =
      props.id === 'np-feed'
        ? targetElement.offsetTop + elementHeight
        : topPosition + elementHeight
    const scrollY = window.scrollY + navigationSectionHeight + 8
    if (topPosition <= scrollY && scrollY < bottomPosition) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }

  useEffect(checkIfActive, [])
  useEffect(() => {
    window.addEventListener('scroll', throttle(checkIfActive, 200))
    return () => {
      window.removeEventListener('scroll', throttle(checkIfActive, 200))
    }
  }, [])

  return (
    <div
      className={isActive ? 'top-nav-bar-button active' : 'top-nav-bar-button'}
      css={css`
        padding: 9px 16px;
        width: fit-content;
        height: 32px;
        color: ${colors.black10};
        background-color: ${colors.gray40};
        border-radius: 36px;
        word-break: keep-all;
        &.active {
          color: ${colors.white00};
          background-color: ${colors.black10};
        }
      `}
      onClick={onClick}
    >
      {props.name}
    </div>
  )
}
