import React from 'react'

type Props = {
  children: React.ReactNode
}

type State = {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return null
    }
    return this.props.children
  }
}

export const withErrorBoundary = (Component: React.ComponentType) => {
  return class ErrorBoundaryComponent extends React.Component {
    render() {
      return (
        <ErrorBoundary>
          <Component {...this.props} />
        </ErrorBoundary>
      )
    }
  }
}
