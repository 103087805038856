import { css } from '@emotion/react'
import { colors, mq } from 'plume-ui'

type Props = {
  className?: string
  children: React.ReactNode
}

export const MobileGradationBackground: React.FC<Props> = (props) => {
  return (
    <div
      css={css`
        ${mq.untilMedium} {
          background: linear-gradient(
            180deg,
            ${colors.gray40} 50%,
            ${colors.white10} 100%
          );
        }
      `}
      className={props.className}
    >
      {props.children}
    </div>
  )
}
