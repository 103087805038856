import * as React from 'react'
import { css } from '@emotion/react'
import { mq } from 'plume-ui'

type LineNumber =
  | number
  | {
      desktop: number
      mobile: number
    }

type Props = {
  children: string | null
  lineNumber: LineNumber
  showAll?: boolean
  height?: number
  size?: string
  stripLineBreaks?: boolean
  justify?: boolean
  lineClampRef?: any
  className?: string
  dataTestId?: string
}

export const LineClampText: React.FC<Props> = ({
  lineNumber,
  showAll = false,
  height = 1.4,
  size = '14px',
  stripLineBreaks = false,
  justify = false,
  lineClampRef,
  className,
  children,
  dataTestId,
}) => {
  if (typeof lineNumber === 'number') {
    lineNumber = { desktop: lineNumber, mobile: lineNumber }
  }
  const text = stripLineBreaks
    ? children?.replace(/(\r\n|\n|\r)/gm, '')
    : children

  return (
    <span
      title={text ?? ''}
      css={css`
        line-height: ${height};
        font-size: ${size};
        ${justify ? 'text-align: justify;' : ''}

        ${showAll ||
        `
          max-height: calc(${size} * ${height} * ${lineNumber.desktop});
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: ${lineNumber.desktop};
          ${mq.untilMedium} {
            max-height: calc(${size} * ${height} * ${lineNumber.mobile});
            -webkit-line-clamp: ${lineNumber.mobile};
          }
        `}
      `}
      className={className}
      ref={lineClampRef}
      data-test-id={dataTestId}
    >
      {text}
    </span>
  )
}
