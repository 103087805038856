import * as React from 'react'
import { useUser } from '../../contexts/AuthContext'
import { isEnterpriseWithTrial } from '@/lib/user'

type Props = {
  children: React.ReactNode
}

export const ForEnterprise: React.FC<Props> = (props) => {
  const user = useUser()
  if (isEnterpriseWithTrial(user)) {
    return <>{props.children}</>
  } else {
    return null
  }
}
